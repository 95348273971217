import React from 'react'
import './card.css'

const Card = () => {

    const cardData = [
        {
            image: "./picOne.jpeg",
            title: "CHEVRON CORPORATIVE",
            description: "Abujo Panel."
        },

        {
            image: "./picTwo.jpeg",
            title: "LASU PROJECT",
            description: "Multi-Purpose Hall."
        },

        {
            image: "./picThree.jpeg",
            title: "BAY VIEW ESTATE",
            description: "Mechanical, Electrical, and Plumbing."
        },

        {
            image: "./picFour.jpeg",
            title: "HOUSE ON THE ROCK CATHEDRAL",
            description: "Light Control Panel."
        },

        {
            image: "./picFive.jpeg",
            title: "GRACE EVENT CENTER",
            description: "Heat, Ventilation, and Air Conditioning."
        },

        {
            image: "./picSix.jpeg",
            title: "JAWA MEP",
            description: "Design (Mechanical, Electrical & Plumbing)."
        },

        {
            image: "./picSeven.jpeg",
            title: "LAKE VIEW ESTATE LEKKI, LAGOS",
            description: "Mechanical, Electrical and Plumbing."
        },

        {
            image: "./picEight.jpeg",
            title: "NICON TOWN ESTATE LEKKI, LAGOS",
            description: "Mechanical, Electrical and Plumbing."
        },

        {
            image: "./picNine.jpeg",
            title: "RCCG ROSE OF SHARON IKEJA",
            description: "Electrical & Heat, Ventilation, and Air Conditioning."
        },

        {
            image: "./picTen.jpeg",
            title: "LASU PROJECT",
            description: "Transformer Installation."
        },

        {
            image: "./picEleven.jpeg",
            title: "BONNY ISLAND INFRASTRUCTURE",
            description: "Electrical Installation."
        },

        {
            image: "./picTwelve.jpeg",
            title: "SHORELINE ESTATE",
            description: "Lighting Installation."
        },

        {
            image: "./picThirteen.jpeg",
            title: "GREENWICH MERCHANT BANK",
            description: "Server Room Electrical Installation."
        },

        {
            image: "./picFourteen.jpeg",
            title: "BONNY ISLAND POLYTECHNIC",
            description: "Electrical Sychronizing Panel."
        },

        {
            image: "./picFifteen.jpeg",
            title: "ADEWALE AYUBA ESTATE",
            description: "Lighting Installation."
        },

        {
            image: "./picSixteen.jpeg",
            title: "SHORTLET LEKKI PHASE 1",
            description: "Design (Mechanical, Electrical & Plumbing)"
        },

        {
            image: "./picSeventeen.jpeg",
            title: "GREENWICH EKO HOTEL, VI",
            description: "Water Treatment Plant."
        },

        {
            image: "./picEighteen.jpeg",
            title: "HOSTEL DEVELOPMENT ONIKE",
            description: "Sewage Treatment Plant."
        },

        {
            image: "./picNineteen.jpeg",
            title: "LUTH IDIARABA",
            description: "Mechanical & Plumbing."
        },

        {
            image: "./picTwenty.jpeg",
            title: "Bay VIEW ESTATE",
            description: "Plumbing."
        },

        {
            image: "./picTwentyOne.jpeg",
            title: "SHONIBARE PROJECT IKEJA",
            description: "Design (Mechanical & Plumbing)."
        },

        {
            image: "./picTwentyTwo.png",
            title: "EMMANUEL CHAPEL, EKITI",
            description: "Design (Mechanical & Plumbing)."
        },
    ];
    
  return (
    <div className="cards-wrapper">
        <div className="card-container">
            {cardData.map((card, index) => (
                <div className="card" key={index}>
                    <img src={card.image} alt={card.description} className="card-image" />
                    
                    <div className="card-text">
                    <h2>{card.title}</h2>
                    <p>{card.description}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>

  )
}

export default Card