import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div>
        <footer>
            <img src='./gmeth-logo.png'/>
            <hr/>

            <div className="divs">
                <div className="reachUs">
                    <h3> Reach us </h3>
                    <br/>
                    <div className="phoneDiv">
                        <div className="phoneNumber">
                            <i class="fa-solid fa-phone-volume"></i>
                            <p> +2348072610767,<br/>+2347060558733.</p>
                        </div>

                        <div className="mailAddress">
                            <i class="fa-solid fa-envelope"></i>
                            <p><a href='mailto:gmethproject@gmail.com'> gmethproject@gmail.com </a> <br/> <span> <a href="mailto: moses.agboola@gmail.com"> moses.agboola@gmail.com </a></span> </p>
                        </div>

                        <div className="homeAddress">
                            <i class="fa-solid fa-location-dot"></i>
                            <p> 88/90, Ondo Street Ebute Meta, Lagos<br/> Nigeria. </p>
                        </div>
                    </div>
                </div>
                <div className="company">
                    <h3> Company </h3>
                    <br/>
                    <div className="p">
                        <p><a href='#about'> About </a></p>
                        <p><a href='#contactUs'> Contact </a></p>
                        <p><a href='#projects'> Projects </a></p>
                    </div>
                </div>
                <div className="service">
                    <h3> Services </h3>
                    <br/>
                    <div className="p">
                        <p> Mechanical </p>
                        <p> Electrical </p>
                        <p> Communications </p>
                        <p> Structural Design </p>
                        <p> Civil </p>
                    </div>
                </div>
                <div className="socialHandles" id='contactUs'>
                    <h3> Social Handles </h3>
                    <br/>
                    <div className="handles">
                        <a href='www.wikipedia.com' target='__blank'> <i class="fa-brands fa-twitter"></i> </a>
                        <a href='' target='__blank'> <i class="fa-brands fa-linkedin-in"></i> </a>
                        <a href='' target='__blank'> <i class="fa-brands fa-instagram"></i> </a>
                        <a href='' target='__blank'> <i class="fa-brands fa-facebook-f"></i> </a>
                        <a href="mailto:gmethproject@gmail.com" target='__blank' > <i class="fa-regular fa-envelope"></i> </a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer