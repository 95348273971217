import React, { useState } from 'react';
import './testimonials.css';

const Testimonials = () => {
  // Initialize state to track the start index of testimonials to display
  const [startIndex, setStartIndex] = useState(0);

  // Sample testimonials data
  const testimonials = [
    {
      title: 'Lake View Estate CEO',
      name: 'Robert John',
      content: 'This is a fantastic service!',
      avatar: './picOne.jpeg', // Add image path
    },
    {
      title: 'Tech Corp Founder',
      name: 'Emily Carter',
      content: 'Highly recommend to everyone!',
      avatar: './picTwo.jpeg', // Add image path
    },
    {
      title: 'Green Garden Manager',
      name: 'Sarah Smith',
      content: 'Exceptional experience from start to finish.',
      avatar: './picThree.jpeg', // Add image path
    },
    {
      title: 'Oceanview Hotels Director',
      name: 'James Brown',
      content: 'A service that truly stands out!',
      avatar: './picFour.jpeg', // Add image path
    },
    // Add more testimonials here
  ];

  const testimonialsToShow = 2;
  const totalTestimonials = testimonials.length;

  // Function to go to the previous testimonials
  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex > 0
        ? prevIndex - testimonialsToShow
        : totalTestimonials - testimonialsToShow
    );
  };

  // Function to go to the next testimonials
  const handleNext = () => {
    setStartIndex((prevIndex) =>
      prevIndex < totalTestimonials - testimonialsToShow
        ? prevIndex + testimonialsToShow
        : 0
    );
  };

  return (
    <div className="testimonial-section" id="testimonials">
      <hr />
      <div className="testimonial-header">
        <h1>Testimonials</h1>
        <div className="testimonial-navigation">
          <button className="nav-button" onClick={handlePrev}>
            &lt;
          </button>
          <button className="nav-button" onClick={handleNext}>
            &gt;
          </button>
        </div>
      </div>

      <div className="testimonial-container">
        {testimonials.slice(startIndex, startIndex + testimonialsToShow).map((testimonial, index) => (
          <div className="testimonial" key={index}>
            <div className="speech-bubble">
              <p>{testimonial.content}</p>
            </div>
            <div className="profile">
              <div className="avatar">
                <img src={testimonial.avatar} alt={`${testimonial.name}'s avatar`} />
              </div>
              <div className="name-title">
                <p>{testimonial.title}</p>
                <p>{testimonial.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
