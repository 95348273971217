import React from 'react'
import './projects.css'
import Card from '../card/Card';

const Projects = () => {
    React.useEffect(() => {
        const tabs = document.querySelectorAll('.tabBtn');
        const allContent = document.querySelectorAll('.content');
    
        tabs.forEach((tab, index) => {
            tab.addEventListener('click', (e) => {
                tabs.forEach(tab => { tab.classList.remove('active') });
                tab.classList.add('active');
    
                var line = document.querySelector('.line');
                line.style.width = e.target.offsetWidth + "px";
                line.style.left = e.target.offsetLeft + "px";
    
                allContent.forEach(content => { content.classList.remove('active') });
                allContent[index].classList.add('active');
            });
        });
    }, []);
    
  return (
    <div>
        <div className="projects">
            <div className="beforeProjects">
                <div className="text">
                    <h3> Doing the right things,<br/> <span style={{color: '#A67796'}}> at the right time. </span></h3>
                </div>

                <div className="divEmployees">
                    <h3> 20 </h3>
                    <span> Specified <br/> Employees </span>
                </div>

                <div className="divProjects">
                    <h3> 100 </h3>
                    <span> Completed <br/> Projects </span>
                </div>

                <div className="divClients">
                    <h3> 100 </h3>
                    <span> Standard <br/> Clients</span>
                </div>
            </div>
            <br/><br/><br/>
            <div className="tab" id='projects'>
                <hr></hr>
                <h1> Projects </h1>
                <br/>
                <div className="subCategory">
                    <div className="container">
                        <div className="tabBox">
                            <button className="tabBtn active"> All </button>
                            <button className="tabBtn"> Designs </button>
                            <button className="tabBtn"> Electrical </button>
                            <button className="tabBtn"> Mechanical </button>
                            <div className="line"></div>
                        </div>

                        <div className="contentBox">
                            <div className="content active">
                                <Card/>
                            </div>

                            <div className="content" id='designs'>
                                <div className="card">
                                    <img src='./picSix.jpeg' alt="JAWA MEP" className="card-image" />
                                    <div className="card-text">
                                        <h2> JAWA MEP </h2>
                                        <p> Design (Mechanical, Electrical & Plumbing). </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picSixteen.jpeg' alt="SHORTLET LEKKI PHASE1" className="card-image" />
                                    <div className="card-text">
                                        <h2> SHORTLET LEKKI PHASE 1 </h2>
                                        <p> Design (Mechanical, Electrical & Plumbing). </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picTwentyOne.jpeg' alt="SHONIBARE PROJECT IKEJA" className="card-image" />
                                    <div className="card-text">
                                        <h2> SHONIBARE PROJECT IKEJA </h2>
                                        <p> Design (Mechanical, Electrical & Plumbing). </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picTwentyTwo.png' alt="EMMANUEL CHAPEL, EKITI" className="card-image" />
                                    <div className="card-text">
                                        <h2> EMMANUEL CHAPEL, EKITI </h2>
                                        <p> Design (Mechanical, Electrical & Plumbing). </p>
                                    </div>
                                </div>
                            </div>

                            <div className="content" id='electrical'>
                                <div className="card">
                                    <img src='./picOne.jpeg' alt="CHEVRON CORPORATIVE" className="card-image" />
                                    <div className="card-text">
                                        <h2> CHEVRON CORPORATIVE </h2>
                                        <p> Abujo Panel. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picTwo.jpeg' alt="LASU PROJECT" className="card-image" />
                                    <div className="card-text">
                                        <h2> LASU PROJECT </h2>
                                        <p> Mutlti-purpose Hall. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picThree.jpeg' alt="BAY VIEW ESTATE" className="card-image" />
                                    <div className="card-text">
                                        <h2> BAY VIEW ESTATE </h2>
                                        <p> Mechanical, Electrical & Plumbing. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picSeven.jpeg' alt="LAKE VIEW ESTATE LEKKI, LAGOS" className="card-image" />
                                    <div className="card-text">
                                        <h2> LAKE VIEW ESTATE LEKKI, LAGOS </h2>
                                        <p> Mechanical, Electrical & Plumbing. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picEight.jpeg' alt="NICON TOWN ESTATE LEKKI, LAGOS" className="card-image" />
                                    <div className="card-text">
                                        <h2> NICON TOWN ESTATE LEKKI, LAGOS </h2>
                                        <p> Mechanical, Electrical & Plumbing. </p>
                                    </div>
                                </div>   

                                <div className="card">
                                    <img src='./picNine.jpeg' alt="RCCG ROSE OF SHARON IKEJA" className="card-image" />
                                    <div className="card-text">
                                        <h2> RCCG ROSE OF SHARON IKEJA </h2>
                                        <p> Electrical & Heat, Ventilation, and Air Conditioning. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picTen.jpeg' alt="LASU PROJECT" className="card-image" />
                                    <div className="card-text">
                                        <h2> LASU PROJECT </h2>
                                        <p> Transformer Installation. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picEleven.jpeg' alt="BONNY ISLAND INFRASTRUCTURE" className="card-image" />
                                    <div className="card-text">
                                        <h2> BONNY ISLAND INFRASTRUCTURE </h2>
                                        <p> Electrical Installation. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picTwelve.jpeg' alt="SHORELINE ESTATE" className="card-image" />
                                    <div className="card-text">
                                        <h2> SHORELINE ESTATE </h2>
                                        <p> Lighting Installation. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picThirteen.jpeg' alt="GREENWICH MERCHANT BANK" className="card-image" />
                                    <div className="card-text">
                                        <h2> GREENWICH MERCHANT BANK </h2>
                                        <p> Server Room Electrical Installation. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picFourteen.jpeg' alt="BONNY ISLAND POLYTECHNIC" className="card-image" />
                                    <div className="card-text">
                                        <h2> BONNY ISLAND POLYTECHNIC </h2>
                                        <p> Electrical Synchronizing Panel. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picFifteen.jpeg' alt="MEP LAKE VIEW" className="card-image" />
                                    <div className="card-text">
                                        <h2> MEP LAKE VIEW </h2>
                                        <p> Estate Lekki, Lagos. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picFour.jpeg' alt="HOUSE ON THE ROCK CATHEDRAL" className="card-image" />
                                    <div className="card-text">
                                        <h2> HOUSE ON THE ROCK CATHEDRAL </h2>
                                        <p> Light Control Panel. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picSixteen.jpeg' alt="LASU PROJECT" className="card-image" />
                                    <div className="card-text">
                                        <h2> SHORTLET LEKKI PHASE 1 </h2> 
                                        <p> Design (Mechanical, Electrical & Plumbing). </p>
                                    </div>
                                </div>
                            </div>

                            <div className="content" id='mechanical'>
                                <div className="card">
                                    <img src='./picSeven.jpeg' alt="LAKE VIEW ESATE LEKKI, LAGOS" className="card-image" />
                                    <div className="card-text">
                                        <h2> LAKE VIEW ESATE LEKKI, LAGOS </h2>
                                        <p> Mechanical, Electrical and Plumbing. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picTwo.jpeg' alt="BAY VIEW ESTATE" className="card-image" />
                                    <div className="card-text">
                                        <h2> BAY VIEW ESTATE </h2>
                                        <p> Mechanical, Electrical and Plumbing. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picEight.jpeg' alt="LASU PROJECT" className="card-image" />
                                    <div className="card-text">
                                        <h2> NICON TOWN ESTATE LEKKI, LAGOS </h2>
                                        <p> Mechanical, Electrical and Plumbing. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picNineteen.jpeg' alt="LUTH IDIARABA" className="card-image" />
                                    <div className="card-text">
                                        <h2> LUTH IDIARABA </h2>
                                        <p> Mechanical & Plumbing. </p>
                                    </div>
                                </div>

                                <div className="card">
                                    <img src='./picSixteen.jpeg' alt="LASU PROJECT" className="card-image" />
                                    <div className="card-text">
                                        <h2> SHORTLET LEKKI PHASE 1 </h2>
                                        <p> Design (Mechanical, Electrical & Plumbing). </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Projects