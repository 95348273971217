import React from 'react'
import './App.css'
import Navbar from './components/nav/Navbar'
import About from './components/about/About'
import HeroSection from './components/hero/HeroSection'
import Services from './components/services/Services'
import Projects from './components/projects/Projects'
import Footer from './components/footer/Footer'
import Testimonials from './components/testimonials/Testimonials'
import ContactForm from './components/form/ContactForm'


const App = () => {
  return (
    <div>
      <Navbar/>
      <HeroSection/>
      <About/>
      <Services/>
      <Projects/>
      <Testimonials/>
      <ContactForm/>
      <br/><br/><br/>
      <Footer/>
    </div>
  )
}

export default App