import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './contactform.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.send(
      'service_rpjedda', 
      'template_7mjvlzk', 
      formData,
      '77yZ8v5NyAc-LqvL6' 
    ).then((result) => {
        alert('Message sent successfully!');
        setLoading(false);
      }, (error) => {
        console.error('Failed to send message:', error.text);
        alert('Failed to send message. Please try again later.');
        setLoading(false);
      });

    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: ''
    });
  };

  return (
    <div className="contact-form-container" id='contactUs'>
      <div className="contact-info">
        <h2> Contact Information </h2>
        <p style={{color: '#C9C9C9'}}>Say something to start a live chat!</p>
        <div className="phone">
          <i class="fa-solid fa-phone-volume"></i>
          <p> +234 807 261 0767,<br/>+234 706 055 8733.</p>
        </div>
        <div className="email">
          <i class="fa-solid fa-envelope"></i>
          <p> gmethproject@gmail.com<br/> moses.agboola@gmail.com </p>
        </div>
        <div className="address">
          <i class="fa-solid fa-location-dot"></i>
          <p> 88/90, Ondo Street Ebute Meta, Lagos<br/> Nigeria. </p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="contact-form">
        <div className="name-fields">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="name-fields"> {/* Reuse the same layout class */}
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Message</label>
          <textarea
            name="message"
            placeholder='Write your message...'
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
