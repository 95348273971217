import React, { useState } from 'react';
import './navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav>
        <div className="nav-logo">
          <a href="#"><img src='/gmeth-logo.png' alt='Logo' /></a>
        </div>

        <div className="nav-links">
          <ul className={isMenuOpen ? 'active' : ''}>
            <li><a href='#about'> About </a></li>
            <li><a href='#services'> Services </a></li>
            <li><a href='#projects'> Projects </a></li>
            <li><a href='#testimonials'> Testimonials </a></li>
            <li><a href='#contactUs'> Contact Us </a></li>
          </ul>
          <div className="icon" onClick={toggleMenu}>
            <i style={{ color: '#fff', fontSize: '24px' }} className="fa-solid fa-bars"></i>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
