import React from 'react'
import './herosection.css'

const HeroSection = () => {
  return (
    <div>
        <div className="hero">
          <div className="overlay">
            <div className="text">
              <h1> You Think it, We<br/> Build it</h1>
              <p> We offer our clients the best construction service</p>
              <br/><br/>
              <div className="button-div">
                <button><a href='#contactUs'> Contact Us </a></button>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default HeroSection