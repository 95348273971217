import React from 'react'
import './about.css'
const About = () => {
  return (
    <>
        <div className="about-section" id='about'>
            <div class="background-container">
                <div class="flex-container">
                    <div class="flex-img">
                        <img src='/about-img.png'/>
                    </div>
                    <div class="flex-text">
                       <div className="about-gmeth-h2">
                            <hr/>
                            <h2> About Gmeth </h2><br/>
                       </div>
                       <div className="about-gmeth-p">
                            <p> <b> G-METH </b> is an indigenous engineering firm<br/> registered with the corporate affairs commission<br/> and has successfully completed projects for a<br/> number of her clients. </p><br/>
                            <p> At <b> G-METH </b>, our commitment is to execute<br/> projects with highest quality on schedule and<br/> within budget while adhering to excellent<br/> standard practices. </p><br/>
                            <p> Our wealth of experience is our edge when it<br/> comes to how we design, execute and manage<br/> projects effectively. </p>
                            <br/>
                            <button><a href='#home'> Learn More</a> </button>
                       </div>
                    </div>
                </div>
            </div>  

        </div>
    </>
  )
}

export default About