import React from 'react';
import './services.css';

// Variables of images stored
const img1 = 'https://s3-alpha-sig.figma.com/img/5456/2ce1/0dbc2c8035e3fc765404ce51e3f2de7b?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EBtqwD3pE4d~n1kBNijedav42NeKCdtmX~mpM~K4SWhKxZCLbj5B4rsHyQUbBrIBe6ulISG4HXjAMFrMRfZJV50PEBTSK0ohejxG-GFGy2dZwGe~U6kt2M3Qu2qt306GnV6SpMG9VHEK6LtrZZNiAYYrf1cM88TdOXjP0AW~eR~ONkXXL13a9tkB8Im6YZH5z86GbSK9yLOZV5vtDq6WLpLMmCGW4iCl4U44~JUDHpnDw9cMUZWRdZMrWipmwt2nMSDL2W0Yjh8Y7k0RB60BioZYXvkC~V1Qd-ld0q0UQLRKFsvNaEy7H1XxmgoK46jTpOOXhyTQCSq1FPr60AdfBA__';
const img2 = "https://s3-alpha-sig.figma.com/img/6f9c/98cf/d4b42846e8a6fb089f52d6533e1ec2f0?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=P7kVBE3V5kFJIrVQ9uWIccQ33lIMwyPff05SzbaBnX0Pxt4LijzyMG9c~~UlwHuNxF8LmZa8TWpLP4KJ9Cr9aR62E31Jzkmm0n-j-XmakpkdbKKOoYSvOOecbm1V48PeY2J8TCcIw6c5IKSl2jTs4bIe~ls0-39DQQH3JMd4qLn02KNEWP6QPYwVaXyjXfjvT~AzuDkoyEbnV-zo32sb4FWDieIammZPgMKi~One85Msr2-egw12yKuQt0a1rzOYiwEePYY2QBNgDuRzwqeXepAFk1qbrnhL5lnGc~aUvUNQIdgaYk584QmNyrXC6RkfWr3P-xa3y2dYJSn9sACkbg__";
const img3 = "https://s3-alpha-sig.figma.com/img/9960/a358/17b65035f4d7d5056354d0dedec84101?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UqdjaGbMmqn8LVvroXBGwuM~FwS4VzH4qzZQXrA6keKw0SkywtRoZG9MmGZhxyKS7kIXF3PhSUrIyVUeMV8-fh747olX2qjGYVzJJrbDHUF8Q2qTZJVGo6R4FgJcOiJXANrLWsBm8j5f8H6VPqbmsl9QIa~2W6iAlzBE-tA~6ukp2Fj0Fvz1~iIXI6iYNZCs3R~CLg-7RClRzHAGrlXQIVIIPS94mC2coKttCHIidCbICNqXMyUs3Le4NDjFQ9MT~WbFSHC~gIUVIWq7C70dFMhBTNHYLrhaMXG9zQej~35VjmojbDmkybyXqMFatY7~hvmtMPbT2rVm-rajmTIn7Q__";

const Data = [
  {
    id: 1,
    imgSrc: img1,
    destTitle: 'STRUCTURAL DESIGN',
    destText: 'We ensure that our clients get best solution tailor-made for their specific purpose.'
  },
  {
    id: 2,
    imgSrc: img2,
    destTitle: 'MECHANICAL SERVICES',
    destText: 'We ensure that our clients get best solution tailor-made for their specific purpose.'
  },
  {
    id: 3,
    imgSrc: img3,
    destTitle: 'ELECTRICAL SERVICES',
    destText: 'We ensure that our clients get best solution tailor-made for their specific purpose.'
  },
];

const Services = () => {
  return (
    <div>
      <div className="services" id='services'>
        <hr />
        <h1>Services</h1>
        <br/><br/><br/>
        <div className="container">
            <div className="card">
                <div className="card-image">
                    <i className="fas fa-tools icon"></i>
                    <img src="./image1.png"/>
                </div>
                <div className="card-text">
                    <h2> STRUCTURAL DESIGN </h2>
                    <p> We ensure that our clients get best solution<br/> tailor-made for their specific purpose. </p>
                </div>
            </div>

            <div className="card">
                <div className="card-image">
                    <i class="fa-solid fa-gears"></i>
                    <img src="./image2.jpeg"/>
                </div>
                <div className="card-text">
                    <h2> MECHANICAL DESIGN </h2>
                    <p> We ensure that our clients get best solution<br/> tailor-made for their specific purpose. </p>
                </div>
            </div>

            <div className="card">
                <div className="card-image">
                    <i class="fa-solid fa-wrench"></i>
                    <img src="./image3.png"/>
                </div>
                <div className="card-text">
                    <h2> ELECTRICAL DESIGN </h2>
                    <p> We ensure that our clients get best solution<br/> tailor-made for their specific purpose. </p>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
